import React from "react"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import { createMarkup } from "../utils"
import md from "markdown-it"
import Image from "./image"

const converter = md()

const Welcome = props => {
  const { block, products } = props
  const markupTitle = converter.renderInline(block.title)
  const markupBody = converter.renderInline(block.body)

  return (
    <section class="c-welcome a-section">
      <div class="a-container">
        <div class="c-welcome__text">
          <h1
            class="a-section__title a-section__title--main a-section__title--decoration"
            dangerouslySetInnerHTML={createMarkup(markupTitle)}
          />
          <p
            class="a-section__paragraph"
            dangerouslySetInnerHTML={createMarkup(markupBody)}
          />
          {block.button_link && (
            <button
              class="a-btn a-btn--primary"
              onClick={() => navigate(block.button_link)}
            >
              {block.button_text}
            </button>
          )}
        </div>
      </div>
      <div class="c-welcome__image">
        <Image image={block.images[0]} />
      </div>
      {products.length > 0 && (
        <div class="a-container a-container--fluid c-welcome__products-container">
          <div class="c-welcome__products">
            <div class="c-welcome__products__inner">
              {products.map(product => (
                <div
                  class="c-welcome__products__item"
                  onClick={() =>
                    navigate(`/prodotto/${product.item_id}`)
                  }
                >
                  <Image
                    image={product.image}
                    className="c-welcome__products__item-image"
                  />
                  <span className="c-welcome__products__item-name">
                    {product.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default injectIntl(Welcome)
