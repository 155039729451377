import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { localize } from "../utils"
import { makeComponent } from "../utils/componentFactory"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const pageQuery = graphql`
  query HomePageQuery {
    allDirectusBlock(
      filter: { page: { eq: "home" } }
      sort: { fields: order, order: ASC }
    ) {
      nodes {
        order
        page
        type
        hidden
        button_link
        images {
          title
          private_hash
          localFile {
            childImageSharp {
              fixed(width: 1024, webpQuality: 100) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
        data {
          body
          title
          lang
          button_text
        }
      }
    }
    allDirectusProductLine {
      nodes {
        data {
          lang
          name
          description
          slug
        }
        image {
          title
          private_hash
          localFile {
            childImageSharp {
              fixed(height: 260) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
      }
    }
    allDirectusProduct(filter: { home: { eq: true } }) {
      nodes {
        item_id
        image {
          title
          private_hash
          localFile {
            childImageSharp {
              fixed(height: 180) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
        data {
          lang
          name
          slug
        }
      }
    }
    allDirectusEditorial(limit: 10) {
      nodes {
        hidden
        image_cover {
          private_hash
          title
          localFile {
            childImageSharp {
              fixed(width: 450) {
                srcSet
                srcSetWebp
              }
            }
          }
        }
        data {
          lang
          slug
          title
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, intl } = props
  const {
    allDirectusBlock,
    allDirectusProductLine,
    allDirectusProduct,
    allDirectusEditorial,
  } = data
  const blocks = allDirectusBlock.nodes.map(block =>
    localize(block, intl.locale)
  )
  const products = allDirectusProduct.nodes.map(product =>
    localize(product, intl.locale)
  )
  const editorials = allDirectusEditorial.nodes
    .map(editorial => localize(editorial, intl.locale))
    .filter(editorial => !editorial.hidden)

  const productLines = allDirectusProductLine.nodes

  return (
    <Layout name="p-homepage">
      <Seo title="Farmamed" />
      <>
        {blocks.filter(block => !block.hidden).map(block => {
          return makeComponent(block.type, {
            block,
            data: { products, editorials, productLines },
          })
        })}
      </>
    </Layout>
  )
}

export default injectIntl(IndexPage)
